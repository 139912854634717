<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					전체 리뷰
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="centered centered--xlarge">
						<div class="centered__wrap">
							<div class="textbox">
								<div class="textbox__icon">
									<img src="@/assets/images/img_warning.png" alt="숙소 없음">
								</div>
								<h3 class="textbox__title">
									등록된 리뷰가 없어요
								</h3>
								<p class="textbox__description">
									해당 숙소를 이용 하시고 <br>
									첫번째 리뷰를 작성해주세요!
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template> 